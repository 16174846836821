import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthView from '../layouts/authLayout.vue'
import DashboardLayout from '../layouts/dashboardLayout.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: AuthView,
        children: [{
            path: '',
            name: 'login',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "about" */ '../modules/auth/pages/userLogin.vue')
        }]
    },
    {
        path: '/dashboard',
        component: DashboardLayout,
        async beforeEnter(to, from, next) {
            var loggedIn
            loggedIn = localStorage.getItem("token")
            console.log(loggedIn);
            if (!loggedIn) {
                next({
                    name: "login",
                    query: { redirectFrom: to.fullPath },
                });
            } else {
                next();
            }
        },
        children: [{
                path: '',
                name: 'dashboard',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../modules/dashboard/pages/indexPage.vue')
            },
            {
                path: '/employees',
                name: 'employees',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../modules/employees/pages/indexPage.vue')
            },
            {
                path: '/invoices',
                name: 'invoices',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../modules/invoices/pages/indexPage.vue')
            }
        ]
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router