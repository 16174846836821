import Vue from 'vue';
import Vuex from 'vuex';
// import Axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

// Modules 
import auth from '../modules/auth/store/index'
import employees from '@/modules/employees/store/index'
import invoices from '@/modules/invoices/store/index'

Vue.use(Vuex);

const getDefaultState = () => {
    return {

    };
};

export default new Vuex.Store({
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {

    },
    mutations: {

    },
    actions: {

    },
    modules: {
        auth,
        employees,
        invoices
    },
});