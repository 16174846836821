<template>
  <div>
    <div class="app--header">
      <div class="d-flex justify-content-end">
        <div>
          <div class="d-flex align-items-center" style="gap: 20px">
            <img
              :src="
                user.profile_picture == null || user.profile_picture == ''
                  ? '/no_user'
                  : config.baseUrl + '/' + user.profile_picture
              "
              alt=""
              style="width: 60px;
                height: 60px;
                object-fit: cover;
                object-position: center;
                border-radius: 50%;
              "
            />
            <div>
                <h5 class="font-weight-bold">{{ user.name }}</h5>
                <div
                  class="d-flex align-items-center small"
                  role="button"
                  style="gap: 5px"
                  @click="userLogout"
                >
                  <span><svg width="12" height="12" viewBox="0 0 48 48">
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="M23.992 6H6v36h18m9-9l9-9l-9-9m-17 8.992h26"/></svg>
                    </span>
                  <span class="text-secondary">Logout</span>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/api";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      config,
    };
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    userLogout(){
        this.logout()
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
};
</script>