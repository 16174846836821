<template>
  <div class="mobile--nav">
    <div class="nav-icon" role="button" @click="drawer = !drawer">
      <svg width="23" height="23" viewBox="0 0 24 24">
        <path
          fill="var(--primary-color)"
          d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z"
        />
      </svg>
    </div>

    <el-drawer :visible.sync="drawer" size="80%" direction="ltr">
      <div class="p-3 mobile--drawer">
        <div>
          <div>
            <div class="d-flex align-items-center" style="gap: 20px">
              <img
                :src="
                  user.profile_picture === null || user.profile_picture === ''
                    ? '/no_user'
                    : config.baseUrl + '/' + user.profile_picture
                "
                alt=""
                style="width: 60px;
                  height: 60px;
                  object-fit: cover;
                  object-position: center;
                  border-radius: 50%;
                "
              />
              <div>
                <h5 class="font-weight-bold">{{ user.name }}</h5>
                <!-- <router-link to="/profile" class="text-secondary small"
                  >View Profile</router-link
                > -->
              </div>
            </div>
          </div>
          <hr />
          <ul class="m-0">
            <li @click="closeDrawer">
              <router-link to="/dashboard">
                <span> <i class="el-icon-s-home"></i> </span>
                <span> Dashboard </span>
              </router-link>
            </li>
            <li @click="closeDrawer">
              <router-link to="/employees">
                <span>
                  <svg width="15" height="15" viewBox="0 0 256 256">
                    <path
                      fill="var(--primary-color)"
                      d="M164.3 177.2a52 52 0 1 0-72.6 0a75.5 75.5 0 0 0-32.1 33.5a12 12 0 0 0 21.6 10.6a52 52 0 0 1 93.6 0a12 12 0 0 0 10.8 6.7a12.3 12.3 0 0 0 5.2-1.2a12 12 0 0 0 5.6-16.1a75.5 75.5 0 0 0-32.1-33.5ZM100 140a28 28 0 1 1 28 28a28.1 28.1 0 0 1-28-28Zm151.2 9.6a12 12 0 0 1-16.8-2.4a47.4 47.4 0 0 0-31.6-18.7a12 12 0 0 1-9.9-9.1a7.8 7.8 0 0 1-.5-1.4a11.9 11.9 0 0 1 8.6-14.6a20 20 0 1 0-23.1-28a12 12 0 1 1-21.6-10.3a44 44 0 1 1 73.4 47.2a71.1 71.1 0 0 1 23.9 20.5a12 12 0 0 1-2.4 16.8Zm-198-21.1a47.4 47.4 0 0 0-31.6 18.7A12.2 12.2 0 0 1 12 152a12.2 12.2 0 0 1-7.2-2.4a12 12 0 0 1-2.4-16.8a71.1 71.1 0 0 1 23.9-20.5a44 44 0 1 1 73.4-47.2a12 12 0 1 1-21.6 10.3a20 20 0 1 0-23.1 28a11.9 11.9 0 0 1 8.6 14.6a7.8 7.8 0 0 1-.5 1.4a12 12 0 0 1-9.9 9.1Z"
                    />
                  </svg>
                </span>
                <span> Employees </span>
              </router-link>
            </li>
            <li @click="closeDrawer">
              <router-link to="/invoices">
                <span> <i class="el-icon-document"></i> </span>
                <span> Invoices </span>
              </router-link>
            </li>
          </ul>
        </div>

        <div>
          <button
            class="primary--btn d-flex w-100 py-3 justify-content-center"
            style="gap: 10px"
            @click="userLogout"
          >
            <span>
              <svg width="15" height="15" viewBox="0 0 48 48">
                <path
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="4"
                  d="M23.992 6H6v36h18m9-9l9-9l-9-9m-17 8.992h26"
                />
              </svg>
            </span>
            <span>Logout</span>
          </button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import config from "@/config/api";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      config,
      drawer: false,
    };
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    closeDrawer() {
      this.drawer = false;
    },
    userLogout(){
        this.logout()
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
};
</script>

