// import Vue from 'vue';
import Axios from '../../../config/http'
import createPersistedState from 'vuex-persistedstate';

// import router from '@/router'

import "toastify-js/src/toastify.css"
import Toastify from 'toastify-js'

// Vue.prototype.$http = http

const getDefaultState = () => {
    return {
        employees: [],
        submitted: false,
        users: [],
        plans: [],
        loading: false,
        generated: false,
        invoice: {}
    };
};

export default {
    namespaced: true,
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        isSubmitted: state => {
            return state.submitted;
        },
        allEmployees: state => {
            return state.employees
        },
        allUsers: state => {
            return state.users
        },
        getInvoice: state => {
            return state.invoice
        },
        allPlans: state => {
            return state.plans
        },
        isLoading: state => {
            return state.loading
        },
        isGenerated: state => {
            return state.generated
        }

    },
    mutations: {
        SET_EMPLOYEES: (state, data) => {
            state.employees = data;
        },
        SET_USERS: (state, data) => {
            state.users = data;
        },
        SET_PLANS: (state, data) => {
            state.plans = data;
        },
        SUBMITTED: (state, data) => {
            state.submitted = data
        },
        GENERATED: (state, data) => {
            state.generated = data
        },
        SET_INVOICE: (state, data) => {
            state.invoice = data
        },
        LOADING_STATUS: (state, data) => {
            state.loading = data
        }

    },
    actions: {
        // View All Employees
        getEmployees({ commit }, payload) {
            Axios().get(`corporate/staff/${payload.id}/get`)
                .then((res) => {
                    commit('SET_EMPLOYEES', res.data.data)
                    console.log(res);
                    return res
                })
                .catch((err) => {
                    return err
                })
        },

        // View All Users
        getUsers({ commit }) {
            Axios().get(`admin/users`)
                .then((res) => {
                    commit('SET_USERS', res.data.data)
                    return res
                })
                .catch((err) => {
                    return err
                })
        },

        // View All Plans
        getPlans({ commit }) {
            Axios().get(`admin/plans/`)
                .then((res) => {
                    commit('SET_PLANS', res.data.data)
                    return res
                })
                .catch((err) => {
                    return err
                })
        },

        // Add Employee
        addEmployee({ commit, dispatch }, payload) {
            commit('LOADING_STATUS', true)
            Axios().post(`corporate/staff/add`, payload)
                .then((res) => {
                    console.log(res);
                    Toastify({
                        text: "Staff Added Successfully",
                        className: "info",
                        style: {
                            background: "green",
                            fontSize: "12px",
                            borderRadius: "5px"
                        }
                    }).showToast();
                    commit('SUBMITTED', true)
                    dispatch('getEmployees', payload)
                    return res
                })
                .catch((err) => {
                    Toastify({
                        text: "Something went wrong",
                        className: "info",
                        style: {
                            background: "red",
                            fontSize: "12px",
                            borderRadius: "5px"
                        }
                    }).showToast();
                    return err
                })
                .finally(() => {
                    commit('LOADING_STATUS', false)
                })
        },

        // Generate Invoice
        deleteEmployee({ commit }, payload) {
            commit('LOADING_STATUS', true)
            Axios().get(`corporate/staff/${payload.corporate_body_id}/delete/${payload.user_id}`)
                .then((res) => {
                    console.log(res.data.data);
                    Toastify({
                        text: "Employee deleted",
                        className: "info",
                        style: {
                            background: "green",
                            fontSize: "12px",
                            borderRadius: "5px"
                        }
                    }).showToast();
                    return res
                })
                .catch((err) => {
                    Toastify({
                        text: "Something went wrong",
                        className: "info",
                        style: {
                            background: "red",
                            fontSize: "12px",
                            borderRadius: "5px"
                        }
                    }).showToast();
                    return err
                })
                .finally(() => {
                    commit('LOADING_STATUS', false)
                })
        },


        // Generate Invoice
        generateInvoice({ commit }, payload) {
            commit('LOADING_STATUS', true)
            Axios().post(`corporate/invoice/generate`, payload)
                .then((res) => {
                    console.log(res.data.data);
                    Toastify({
                        text: "Invoice Generated",
                        className: "info",
                        style: {
                            background: "green",
                            fontSize: "12px",
                            borderRadius: "5px"
                        }
                    }).showToast();
                    commit('GENERATED', true)
                    return res
                })
                .catch((err) => {
                    Toastify({
                        text: "Something went wrong",
                        className: "info",
                        style: {
                            background: "red",
                            fontSize: "12px",
                            borderRadius: "5px"
                        }
                    }).showToast();
                    return err
                })
                .finally(() => {
                    commit('LOADING_STATUS', false)
                })
        },




        // Remove Submitted
        removeSubmitted({ commit }) {
            commit('SUBMITTED', false)
            commit('GENERATED', false)
        }
    }
};