// import Vue from 'vue';
import Axios from '../../../config/http'
import createPersistedState from 'vuex-persistedstate';

// import router from '@/router'

// import "toastify-js/src/toastify.css"
// import Toastify from 'toastify-js'

// Vue.prototype.$http = http

const getDefaultState = () => {
    return {
        invoices: [],
        loading: false,
        invoice: {}
    };
};

export default {
    namespaced: true,
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        allInvoices: state => {
            return state.invoices
        },
        isLoading: state => {
            return state.loading
        },
        invoice: state => {
            return state.invoice
        }

    },
    mutations: {

        SET_INVOICE: (state, data) => {
            state.invoice = data
        },
        SET_INVOICES: (state, data) => {
            state.invoices = data
        },
        LOADING_STATUS: (state, data) => {
            state.loading = data
        }

    },
    actions: {
        // View All Employees
        getInvoices({ commit }, payload) {
            commit('LOADING_STATUS', true)
            Axios().post(`corporate/invoice/get/${payload.id}`)
                .then((res) => {
                    commit('SET_INVOICES', res.data.data)
                    console.log(res);
                    return res
                })
                .catch((err) => {
                    return err
                })
                .finally(() => {
                    commit('LOADING_STATUS', false)
                })
        },

    }
};