<template>
    <div>
        <div>
            <div>
                <div>
                    <AppDrawer/>
                </div>
                <div class="main--content">
                    <AppHeader class="container--fluid"/>
                    <MobileHeader/>
                    <router-view class="container--fluid"/>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/dashboard/appHeader.vue';
import MobileHeader from '@/components/dashboard/mobileHeader.vue';
import AppDrawer from '../components/dashboard/appDrawer.vue';
export default {
    components: { AppHeader, AppDrawer, MobileHeader }
}
</script>