// import Vue from 'vue';
import Axios from '../../../config/http'
import createPersistedState from 'vuex-persistedstate';

import router from '@/router'

import "toastify-js/src/toastify.css"
import Toastify from 'toastify-js'

// Vue.prototype.$http = http

const getDefaultState = () => {
    return {
        token: '',
        user: {},
        loggedIn: false,
        loading: false
    };
};

export default {
    namespaced: true,
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        isAuthenticated: state => {
            return state.token;
        },
        isLoggedIn: state => {
            return state.loggedIn
        },
        getUser: state => {
            return state.user;
        },
        isLoading: state => {
            return state.loading;
        }
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_LOGGED_IN: state => {
            state.loggedIn = true;
        },
        SET_USER: (state, user) => {
            state.user = user;
        },
        SET_LOADING: (state) => {
            state.loading = true
        },
        END_LOADING: (state) => {
            state.loading = false
        },
        RESET: state => {
            Object.assign(state, getDefaultState());
        }
    },
    actions: {
        // User Login
        async userLogin({ commit }, payload) {
            commit("SET_LOADING")
            try {
                const res = await Axios().post('/corporate/login', payload)
                commit('SET_TOKEN', res.data.access_token);
                commit('SET_USER', res.data.user);
                let token = res.data.access_token
                localStorage.setItem('token', token)
                let loggedIn = true
                commit('SET_LOGGED_IN', loggedIn)
                Toastify({
                    text: "Logged In",
                    className: "info",
                    style: {
                        background: "green",
                        fontSize: "12px",
                        borderRadius: "5px"
                    }
                }).showToast();
                router.push('/dashboard');

                console.log(res);
            } catch (error) {
                Toastify({
                    text: "Incorrect Login Credentials",
                    className: "info",
                    style: {
                        background: "red",
                        fontSize: "12px",
                        borderRadius: "5px"
                    }
                }).showToast();
                return error
            } finally {
                commit("END_LOADING")
            }
        },
        async logout({ commit }) {
            // const res = await Axios().post('/corporate/logout')
            // console.log(res);
            commit('RESET', '');
            localStorage.removeItem('token')
            router.push('/')
        },
    }
};